<template>
  <v-card
    class="mx-auto"
    height="400px"
    style="overflow-y: auto; margin-bottom: 28px;"
    v-if="show != false"
  >
    <v-toolbar color="mattBlack" dark fixed dense>
      <v-toolbar-title>{{label}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn color="secondary" tile @click="agregar" v-if="this.readonly != true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <list
      v-bind:items="items"
      :getTitle="listGetTitle"
      :getSubTitle="listGetSubTitle"
      :actions="actions"
      empty_message="Ningún atributo definido"
    ></list>
  </v-card>
</template>

<script>
import List from '@/components/modulo/List.vue'
import Dialog from '@/components/dialog'
import Form from '@/components/form'

export default {
  components: {
    list: List
  },
  props: {
    type: String,
    name: String,
    label: String,
    default: Object,
    readonly: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    title: Function,
    visibility: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.value = this.default || {}
  },
  data() {
    return {
      value: {},
      schema: {
        key: {
          label: 'Nombre',
          type: String
        },
        value: {
          label: 'Valor',
          type: String
        }
      },
      message: null,
      error: false,
      icon: 'mdi-image-outline',
      show: this.visibility,
      firstLoad: true,
      disabled: true,
      dense: false,
      twoLine: true,
      threeLine: false,
      shaped: false,
      flat: false,
      subheader: false,
      inactive: false,
      subGroup: false,
      nav: false,
      avatar: false,
      rounded: false,
      actions: [{
        help: 'Editar',
        color: 'accent',
        icon: 'mdi-pencil',
        action: this.editar,
        condition: () => this.readonly !== true
      }, {
        help: 'Ver',
        color: 'mattBlack',
        icon: 'mdi-eye',
        action: this.ver
      }, {
        help: 'Eliminar',
        color: 'red',
        icon: 'mdi-delete',
        action: this.eliminar,
        condition: () => this.readonly !== true
      }]
    }
  },
  methods: {
    getItem() {
      return { name: this.name, value: this.value }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    listGetTitle: (item) => item.value,
    listGetSubTitle: (item) => item.key,
    form(action, item) {
      const form = new Form({
        schema: this.schema,
        item,
        readonly: action === 'ver'
      })
      const dialog = new Dialog({
        title: `Agregar ${this.label}`,
        actions: [{
          icon: 'mdi-plus',
          help: 'Agregar',
          color: 'secondary',
          condition: () => action === 'agregar',
          action: () => {
            if (form.hasErrors()) {
              return
            }
            const it = form.getItem()
            this.$set(this.value, it.key, it.value)
            dialog.close()
          }
        }, {
          icon: 'mdi-content-save',
          help: 'Guardar',
          color: 'secondary',
          condition: () => action === 'editar',
          action: () => {
            if (form.hasErrors()) {
              return
            }
            const it = form.getItem()
            this.$set(this.value, it.key, it.value)
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    agregar() {
      this.form('agregar')
    },
    editar(item) {
      this.form('editar', item)
    },
    ver(item) {
      this.form('ver', item)
    },
    eliminar(item) {
      this.$delete(this.value, item.key)
    }
  },
  computed: {
    items() {
      return Object.keys(this.value).map((key) => ({ key, value: this.value[key] }))
    }
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
    }
  }
}
</script>
